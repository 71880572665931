import React, { ReactNode } from "react"

import { Sofa, Bag, BigBag, Phone, Wheel, Ring } from "./img"

interface ICard {
  titleCard: string
  descriptionCard: ReactNode
  image: JSX.Element
}

export const CARDS: ICard[] = [
  {
    titleCard: "С 01.04 –",
    descriptionCard: (
      <>
        категории «
        <a href="https://halvacard.ru/shops/tovary-dlya-remonta" target="_blank" rel="noreferrer">
          Товары для ремонта
        </a>
        » и «
        <a href="https://halvacard.ru/shops/mebel-i-interer" target="_blank" rel="noreferrer">
          Мебель и товары для дома
        </a>
        »
      </>
    ),
    image: <Sofa alt="sofa" />,
  },
  {
    titleCard: "С 08.04 –",
    descriptionCard: (
      <>
        категории «
        <a href="https://halvacard.ru/shops/avto" target="_blank" rel="noreferrer">
          Авто
        </a>
        » <br /> и «
        <a href="https://halvacard.ru/shops/zapravki" target="_blank" rel="noreferrer">
          Заправки
        </a>
        »
      </>
    ),
    image: <Wheel alt="Wheel" />,
  },
  {
    titleCard: "С 15.04 –",
    descriptionCard: (
      <>
        категории «
        <a href="https://halvacard.ru/shops/odezhda" target="_blank" rel="noreferrer">
          Одежда и аксессуары
        </a>
        » и «
        <a href="https://halvacard.ru/shops/obuv" target="_blank" rel="noreferrer">
          Обувь
        </a>
        »
      </>
    ),
    image: <Bag alt="Bag" />,
  },
  {
    titleCard: "С 22.04 –",
    descriptionCard: (
      <>
        категория <br /> «
        <a href="https://halvacard.ru/shops/turizm" target="_blank" rel="noreferrer">
          Путешествия
        </a>
        »
      </>
    ),
    image: <BigBag alt="BigBag" />,
  },
  {
    titleCard: "С 29.04 –",
    descriptionCard: (
      <>
        категория <br /> «
        <a href="https://halvacard.ru/shops/elektronika" target="_blank" rel="noreferrer">
          Электроника
        </a>
        »
      </>
    ),
    image: <Phone alt="Phone" />,
  },
  {
    titleCard: "С 06.05 –",
    descriptionCard: (
      <>
        категория «
        <a href="https://halvacard.ru/shops/yuvelirnye-izdeliya" target="_blank" rel="noreferrer">
          Ювелирные <br /> изделия
        </a>
        »
      </>
    ),
    image: <Ring alt="Ring" />,
  },
]
