import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import { useObserver } from "../../../hooks/useObserver"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить карту"

type BannerProps = {
  title: string
  description: string
  orderNum?: string
}

export function Banner({ title, description, orderNum }: BannerProps) {
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])

  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <Container className={styles.container}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.data}>01.04 – 19.05</div>
          <div className={styles.text}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </Container>
      </Container>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </section>
  )
}
