// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--YMkrJ";
export var btnBlock = "banner-module--btnBlock--6dPEb";
export var container = "banner-module--container--ceTAP";
export var data = "banner-module--data--ejGtJ";
export var hideMobBlock = "banner-module--hideMobBlock--3vrgQ";
export var img = "banner-module--img--pFXem";
export var mobBtn = "banner-module--mobBtn--eOmQX";
export var root = "banner-module--root--HF-rN";
export var section = "banner-module--section--q6OKf";
export var text = "banner-module--text--nuHCf";